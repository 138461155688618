import React from 'react'
import styled, { css } from 'styled-components'

const StyledBox = styled.div`
  position: relative;
  font-size: 20px;
  font-weight: 300;
  background-color: ${(props) => props.theme.color.main};
  color: ${(props) => props.theme.color.text.secondary};
  width: 100%;
  height: 100%;
  padding: 15px 15px;

  h3 {
    margin-top: 25px;
  }

  ul {
    font-size: 16px !important;
    margin-bottom: 2rem;

    li {
      margin-bottom: 10px;
    }
  }

  & p a {
    text-decoration: underline;

    &:hover {
      color: #d50022 !important;
    }
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    //border-top: 16.5px solid transparent;
    border-top: 15px solid transparent;
    //border-right: 15px solid #fff;
    border-right: 15px solid #fff;
    background: transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  ${(props) =>
    props.big &&
    css`
      &::after {
        width: 0;
        height: 0;
        position: absolute;
        right: -5px;
        bottom: 0;
        border-top: 30px solid transparent;
        border-right: 30px solid #fff;
      }
    `};

  @media (min-width: 992px) {
    & h2 {
      line-height: 50px;
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 575px) {
    & p {
      padding-right: 0 !important;
    }
  }
`

function BoxDefault({ children, big, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledBox big={big} {...rest}>
      {children}
    </StyledBox>
  )
}

export default BoxDefault
