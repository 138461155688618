/* eslint-disable import/extensions */
import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from './ButtonShell'

import ChevronRightIcon from '../../img/icons/chevron-right.svg'

const StyledButton = styled.div`
  display: inline-block;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  transition: transform 0.25s ease 0s;

  &:hover {
    transform: scale(1.05);
  }

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-right: 1rem solid ${(props) => props.wedgeColor};
    background: transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  & > a,
  & > button {
    text-decoration: unset;
    background-color: ${(props) => props.theme.color.contrast};
    color: ${(props) => props.theme.color.text.secondary} !important;
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 0;
    position: relative;

    &::after {
      content: '';
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 20px;
      margin-right: 5px;
    }

    &:hover {
    }
  }

  ${(props) =>
    props.black &&
    css`
      & > a,
      & > button {
        background: ${props.theme.color.main};
        color: #fff;
      }
    `}

  ${(props) =>
    props.back &&
    css`
      &::after {
        //border-top: 16.5px solid transparent;
        border-top: 1rem solid transparent;
        border-right: unset;
        border-left: 1rem solid #fff;
        background: transparent;
        left: 0;
        bottom: 0;
        z-index: 2;
      }

      & > a,
      & > button {
        padding-left: 0 !important;
        padding-right: 15px !important;

        &::before {
          content: '';
          display: block;
          background-image: url(${ChevronRightIcon});
          background-size: contain;
          width: 14px;
          height: 18px;
          background-repeat: no-repeat;
          margin-left: 5px;
          margin-right: 20px;
          transform: rotate(180deg);
        }

        &::after {
          display: none;
        }
      }
    `}
`

function ButtonDefault({
  isAnchor,
  isCustom,
  to,
  children,
  className,
  black,
  back,
  target,
  wedgeColor = '#fff',
}) {
  return (
    <StyledButton
      wedgeColor={wedgeColor}
      black={black}
      back={back}
      className={className}
    >
      {isCustom ? (
        children
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor} target={target}>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonDefault
