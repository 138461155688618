/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import CustomLink from 'components/shared/CustomLink'

function ButtonShell({ isAnchor, to, target, children, className }) {
  return !isAnchor ? (
    <CustomLink to={to} className={className} target={target}>
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className} target={target}>
      {children}
    </a>
  )
}

export default ButtonShell
