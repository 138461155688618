import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

function Facebook({ white, ...rest }) {
  const {
    wpComponent: {
      socials: { facebook },
    },
  } = useStaticQuery(graphql`
    query {
      wpComponent(databaseId: { eq: 43 }) {
        socials {
          facebook
        }
      }
    }
  `)

  return (
    <a {...rest} target="_blank" rel="noreferrer noopener" href={facebook}>
      <span style={{ fontSize: 0 }}>Facebook</span>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="97.75px"
        height="97.75px"
        viewBox="0 0 97.75 97.75"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill={white ? '#fff' : '#1c1c1c'}
            d="M48.875,0C21.882,0,0,21.882,0,48.875S21.882,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.868,0,48.875,0z
		 M67.521,24.89l-6.76,0.003c-5.301,0-6.326,2.519-6.326,6.215v8.15h12.641L67.07,52.023H54.436v32.758H41.251V52.023H30.229V39.258
		h11.022v-9.414c0-10.925,6.675-16.875,16.42-16.875l9.851,0.015V24.89L67.521,24.89z"
          />
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </a>
  )
}

export default Facebook
