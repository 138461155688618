/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

const StyledHero = styled.div`
  height: 576px;
  position: relative;
  max-width: 2000px;
  margin-inline: auto;

  @media (max-width: 991px) {
    height: 351px;
  }

  .content {
    padding-top: 150px;
    padding-bottom: 50px;

    & h2 {
      text-shadow: 0px 0px 10px #000000a8;
    }
  }
`

const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: linear-gradient(360deg, #1c1c1c 4.17%, rgba(28, 28, 28, 0) 100%);
`

const Background = styled(Plaatjie)`
  background-color: #e1e1e1;
  height: 351px;

  @media (min-width: 992px) {
    height: 100%;
  }

  @media (max-width: 991px) {
    & img {
      object-position: center right;
    }
  }
`

const Title = styled.h1`
  -webkit-text-fill-color: transparent;
  text-align: right;
  display: block;
  font-weight: 600;
  line-height: 80%;

  @media (min-width: 992px) {
    -webkit-text-stroke: 2px white;
    font-size: 150px;
  }
  @media (max-width: 991px) {
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    -webkit-text-stroke: 1px white;
    font-size: 80px;
  }
  @media (max-width: 575px) {
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
    -webkit-text-stroke: 1px white;
    font-size: 60px;
  }
`

const PreTitle = styled.span`
  color: #d50021;
  font-family: ${(props) => props.theme.font.family.secondary};
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  margin-bottom: -2.5rem;
  text-align: right;

  @media (min-width: 992px) {
    font-size: 80px;
  }

  @media (max-width: 991.98px) {
    font-size: 40px;
  }
`

const Hero = ({ src, pretitle, title, children, center }) => {
  return (
    <StyledHero>
      <Background image={src} className="position-absolute" />
      <Gradient />
      <div className="content container h-100">
        <div className="row align-items-center justify-content-end h-100">
          {center ? (
            <div className="col-12">
              <h2 className="text-center color-text-secondary pb-5 mb-0">
                {title}
              </h2>
            </div>
          ) : (
            <div className="col-lg-10">
              {pretitle && <PreTitle>{pretitle}</PreTitle>}
              <Title stringLength={title.length} className="mb-5">
                {title}
              </Title>
            </div>
          )}
        </div>
      </div>

      {children && (
        <div className="py-5 color-background-main text-white w-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">{children}</div>
            </div>
          </div>
        </div>
      )}
    </StyledHero>
  )
}

export default Hero
