import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

import ChevronRightIcon from '../img/icons/chevron-right.svg'

export const mainTheme = {
  color: {
    main: '#1C1C1C',
    contrast: '#D50032',
    secondary: '#FFFFFF',
    grey: '#DEDEDE',
    greyAlt: '#D8D8D8',
    greyAlt2: '#EAEAEA',

    text: {
      main: '#1C1C1C',
      contrast: '#D50032',
      secondary: '#FFFFFF',
      grey: '#979797',
    },
  },

  font: {
    family: {
      main: 'Montserrat, Arial, Verdana, sans-serif',
      secondary: 'Saira Extra Condensed, Arial, Verdana, sans-serif',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '18px',
      xml: '20px',
      l: '24px',
      ll: '30px',
      xl: '32px',
      xxl: '50px',
      xxxl: '75px',
    },

    weight: {
      xs: '200',
      300: '300',
      s: '500',
      m: '400',
      l: '600',
      xl: '700',
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.color.text.main};
  }
  
  .color {
    &-background {
      &-main { background-color: ${(props) => props.theme.color.main} }
      &-contrast { background-color: ${(props) => props.theme.color.contrast} }
      &-secondary { background-color: ${(props) =>
        props.theme.color.secondary} }
      &-greyAlt { background-color: ${(props) => props.theme.color.greyAlt} }
      &-greyAlt2 { background-color: ${(props) => props.theme.color.greyAlt2} }
    }
    
    &-text {
      &-main { color: ${(props) => props.theme.color.text.main} }
      &-contrast { color: ${(props) => props.theme.color.text.contrast} }
      &-secondary { color: ${(props) => props.theme.color.text.secondary} }
      &-grey { color: ${(props) => props.theme.color.text.grey} }
    }
  }

  .line-height {
    &-xl { line-height: ${(props) => props.theme.font.size.xl}; }
  }
  
  .font {
    &-family {
      &-main { font-family: ${(props) => props.theme.font.family.main} }
      &-secondary { font-family: ${(props) =>
        props.theme.font.family.secondary} }
    }
    
    &-size {
      &-xxs { font-size: ${(props) => props.theme.font.size.xxs} }
      &-xs { font-size: ${(props) => props.theme.font.size.xs} }
      &-s { font-size: ${(props) => props.theme.font.size.s} }
      &-sm { font-size: ${(props) => props.theme.font.size.sm} }
      &-m { font-size: ${(props) => props.theme.font.size.m} }
      &-xm { font-size: ${(props) => props.theme.font.size.xm} }
      &-xml { font-size: ${(props) => props.theme.font.size.xml} }
      &-l { font-size: ${(props) => props.theme.font.size.l} }
      &-ll { font-size: ${(props) => props.theme.font.size.ll} }
      &-xl { font-size: ${(props) => props.theme.font.size.xl} }
      &-xxl { font-size: ${(props) => props.theme.font.size.xxl} }
      &-xxxl { font-size: ${(props) => props.theme.font.size.xxxl} }
    }
    
    &-weight {
      &-100 { font-weight: 100 }
      &-200 { font-weight: 200 }
      &-300 { font-weight:300 }
      &-400 { font-weight: 400 }
      &-500 { font-weight: 500 }
      &-600 { font-weight: 600 }
      &-700 { font-weight: 700 }
      &-800 { font-weight: 800 }
      &-900 { font-weight: 900 }
    }
  }
  
  h1 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.xxl};
    color: ${(props) => props.theme.color.text.contrast};
    text-transform: uppercase;
    font-weight: normal;
    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }
  
  h2 {
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size.xxxl};
    color: ${(props) => props.theme.color.text.main};
    text-transform: uppercase;
    font-weight: bold;
    z-index: 1;

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xxl};
    }

    @media (max-width: 575px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  p, span {
    line-height: 156.25%;
  }
  
  a {
    color: ${(props) => props.theme.color.text.contrast};
    text-decoration: none;
    font-family: ${(props) => props.theme.font.family.main};
    
  }

  a.custom-button {
  display: inline-block;
  position: relative;
  font-size: 20px;
  font-weight: 300;
  transition: transform 0.25s ease 0s;

  text-decoration: unset;
    background-color: ${(props) => props.theme.color.contrast};
    color: ${(props) => props.theme.color.text.secondary};
    padding: 10px 15px;
    width: 70%;
    height: 100%;
    padding-right: 0;
    position: relative;
    
  &:hover {
    transform: scale(1.05);
  }
  
  &::after {
    position:absolute;
    content: "";
    width: 0;
    height: 0;
    //border-top: 16.5px solid transparent;
    border-top: 1rem solid transparent;
    //border-right: 15px solid #fff;
    border-right: 1rem solid ${(props) => props.wedgeColor};
    background: transparent;
    right: -5px;
    bottom: -1px;
    z-index: 2;
  }
  
    
    &::after {
      content: "";
      display: block;
      background-image: url(${ChevronRightIcon});
      background-size: contain;
      width: 14px;
      height: 18px;
      background-repeat: no-repeat;
      margin-left: 20px;
      margin-right: 5px;
    }

}
  
  /* .wedge-main {
    position: relative;
    &::after {
      z-index: -1;
      content: "";
      position:absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
      background: ${(props) => props.theme.color.main};
      width: 54px;
      height: 54px;
      transform: rotate(45deg);
    }
  } */
  
  .shadow {
    box-shadow: 0 0 25px 0 rgba(0,0,0, 0.5);
  }
  
  .z-index-2 {
    z-index: 2 !important;
  }
  
  input, textarea {
    font-size: ${(props) => props.theme.font.size.m};
  }
  
  textarea {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  @media (max-width: 1199.98px) {
  
  }
  
  @media (max-width: 991.98px) { 
    .font-size-ll {
       font-size: 21px;
    }
  }
  
  @media (max-width: 767.98px) {
  
  }
  
  @media (max-width: 575.98px) { 
  
  }
  
  #rcc-confirm-button {
    background-color: #000000 !important;
    color: #ffffff !important;
  }
`

function Theme({ children }) {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Theme
