/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { motion } from 'framer-motion'
import CustomLink from 'components/shared/CustomLink'
import { useMedia } from 'use-media'
import Logo from '../Logo'
import Phone from '../Phone'
import Linkedin from '../Linkedin'
import Facebook from '../Facebook'
import Instagram from '../Instagram'
import Icon from '../elements/Icon'
import chevron from '../../img/icons/chevron-down.svg'

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`

const StyledTopmenu = styled.div`
  background-color: #000;

  & li > a {
    color: #fff !important;
  }
`

const StyledHeader = styled.div`
  background-color: #d8d8d8;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  @media (min-width: 992px) {
    height: ${(props) => (props.isSticky ? 65 : 85)}px;
  }

  @media (max-width: 991px) {
    height: 65px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Brand = styled(CustomLink)`
  font-size: 0;
  position: relative;
  margin-bottom: -150px;
  z-index: 100;

  & > img {
    height: 30px;
    width: 30px;
  }

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  & svg {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  @media (min-width: 1200px) {
    top: ${(props) => (props.isSticky ? -61 : -22)}px;

    & svg {
      height: ${(props) => (props.isSticky ? 100 : 192)}px;
      width: ${(props) => (props.isSticky ? 110 : 221)}px;
    }
  }

  @media (max-width: 1199px) {
    top: -71px;

    & svg {
      height: 100px;
      width: 110px;
    }
  }

  @media (max-width: 991px) {
    top: -75px;
    left: -15px;

    & svg {
      height: 65px;
      width: 75px;
    }
  }
`

const NavButton = styled.button`
  position: absolute;
  top: 0px;
  right: 5px;
  width: 60px;
  height: 40px;
  padding: 0 10px;
`

const Header = ({ isSticky }) => {
  const {
    wpComponent: {
      componentHeader: { menu, topmenu },
    },
  } = useStaticQuery(graphql`
    {
      wpComponent(databaseId: { eq: 2044 }) {
        componentHeader {
          topmenu {
            link {
              title
              url
            }
          }
          menu {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <StyledNav>
      <StyledTopmenu>
        <Container className="container h-100">
          <HeaderTopmenuDesktop items={topmenu} isSticky={isSticky} />
        </Container>
      </StyledTopmenu>
      <StyledHeader isSticky={isSticky}>
        <Container className="container h-100">
          <Brand isSticky={isSticky} to="/">
            <Logo />
            Home
          </Brand>

          <NavButton
            type="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="d-block d-lg-none h-100"
            aria-label="Open menu"
          >
            <Icon icon="menu" />
          </NavButton>

          <HeaderMenuMobile
            items={[...menu, ...topmenu]}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <HeaderMenuDesktop items={menu} />
        </Container>
      </StyledHeader>
    </StyledNav>
  )
}

const StyledHeaderMenuMobile = styled(motion.div)`
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  overflow: hidden;
  display: none;
  box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 991.98px) {
    display: block;
  }

  ul {
    padding-top: 20px;
    list-style: none;
    padding-left: 35px;
    padding-right: 35px;
    display: flex;
    flex-direction: column;

    li {
      align-self: start;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;

      & a {
        color: #000 !important;
      }

      svg {
        color: #000 !important;
        width: 24px;
        height: 24px;
      }

      a,
      .link {
        font-size: 20px;
        color: #000 !important;
        text-shadow: unset;
        background: transparent;
        position: relative;

        > span {
          display: block;
          position: relative;
          z-index: 99;
        }
      }

      &.contact {
        background: transparent;
        padding: initial;
        text-shadow: unset;
      }

      @media (min-width: 992px) {
        align-items: center;
      }
    }
  }
`

const HeaderMenuMobile = ({ items, isCollapsed, setIsCollapsed }) => (
  <StyledHeaderMenuMobile
    animate={{
      height: isCollapsed ? '100%' : 0,
    }}
    transition={{ duration: 0.2 }}
    initial={false}
  >
    <NavButton
      type="button"
      aria-label="Close menu"
      className="mt-3"
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <Icon icon="x" />
    </NavButton>

    <ul className="h-100 d-flex justify-content-center">
      <HeaderMenuItem items={items} />
    </ul>
  </StyledHeaderMenuMobile>
)

const StyledHeaderTopmenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding-top: ${(props) => (props.isSticky ? '7px' : '15px')};
  padding-bottom: ${(props) => (props.isSticky ? '7px' : '15px')};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  @media (min-width: 992px) {
    padding-left: 15.5rem;
  }
`

const StyledHeaderMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;

  & a,
  .link {
    font-size: 25px !important;

    > span {
      display: block;
      position: relative;
      z-index: 99;
    }
  }
`

const Socials = styled.div`
  display: flex;

  & a {
    margin-left: 0;
    display: block;
  }
`

const HeaderTopmenuDesktop = ({ items, isSticky }) => (
  <StyledHeaderTopmenuDesktop className="d-none d-lg-flex" isSticky={isSticky}>
    <HeaderMenuItem items={items} />
    <Socials>
      <Social>
        <Linkedin />
      </Social>
      <Social>
        <Facebook />
      </Social>
      <Social>
        <Instagram />
      </Social>
    </Socials>
  </StyledHeaderTopmenuDesktop>
)

const HeaderMenuDesktop = ({ items }) => (
  <StyledHeaderMenuDesktop className="d-none d-lg-flex">
    <HeaderMenuItem items={items} />
    <div>
      <Phone className="mr-3" />
    </div>
  </StyledHeaderMenuDesktop>
)

const Item = styled.li`
  flex-direction: column;
  & > a,
  & > .link {
    color: ${(props) => (props.active ? '#FFF' : '#8a8a8a')};
    font-family: ${(props) => props.theme.font.family.secondary};
    text-transform: uppercase;
    text-decoration: unset;
    font-weight: 600;
    font-size: ${(props) => props.theme.font.size.xml};
    display: flex;
    flex-direction: column;
    position: relative;

    @media (min-width: 992px) {
      align-items: center;
    }

    &[aria-current] {
      color: ${(props) => props.theme.color.text.main};

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: absolute;
        bottom: -3px;
        border-bottom: 5px solid ${(props) => props.theme.color.contrast};

        @media (max-width: 991.98px) {
          bottom: 17px;
          left: -15px;
          transform: rotate(90deg);
        }
      }
    }

    &:hover {
    }

    @media (max-width: 991.98px) {
      font-size: 28px !important;
      width: fit-content;
      color: #8d8d8d !important;

      ${(props) =>
        props.hasSubmenu &&
        css`
          &::before {
            content: url(${chevron});
            position: absolute;
            display: block;
            top: -6px;
            right: -20px;
            width: 5px;
            height: 5px;
          }
        `}
    }
    > span {
      display: block;
      position: relative;
      z-index: 99;
    }
  }
`

const Social = styled.div`
  position: relative;
  aspect-ratio: 1/1;
  z-index: 2;
  display: block;
  margin: 0 5px;

  &:hover:before {
    background-color: #d50033;
  }

  & svg {
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
  }

  &:before {
    position: absolute;
    content: '';
    top: 1px;
    left: 1px;
    background-color: #fff;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    z-index: 1;
  }
`

const Submenu = styled.div`
  @media (min-width: 992px) {
    :after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      border-bottom: 30px solid black;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
    }

    :after {
      border-left: 30px solid black;
    }

    :after {
      bottom: auto;
      top: 100%;
      border-bottom: none;
      border-top: 30px solid black;
    }

    ul li > a {
      color: #fff !important;
    }
  }

  > ul {
    padding-left: 0;
    padding-inline: 1rem;
    list-style: none;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    li > a {
      font-size: 14px !important;
      display: flex;
      align-items: center;
      font-weight: 300;
      text-transform: none !important;
      padding-block: 0.25rem;

      svg {
        margin-right: 10px;
        min-width: 11px;
        min-height: 6px;
      }

      :hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 991px) {
      background-color: #d9d9d9;
      left: -20%;
      padding-bottom: 1rem;

      :after {
        position: absolute;
        content: '';
        left: 90%;
        top: 0;
        height: 100%;
        width: 2000px;
        background-color: #d9d9d9;
      }
    }

    @media (min-width: 992px) {
      background-color: #000;
    }
  }

  @media (min-width: 992px) {
    width: 200%;
    position: absolute;
    top: -25px;
    left: -50%;

    > ul {
      padding-top: 6rem;
      padding-bottom: 1.5rem;
    }
  }

  @media (max-width: 991.98px) {
    z-index: 100;
    top: 40px;

    li > a {
      margin-block: 0.5rem;
    }
  }
`

const HeaderMenuItem = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  const isDesktop = useMedia({ minWidth: 992 })

  return (
    <>
      <Item key={-1} className="d-lg-none">
        <Link to="/">HOME</Link>
      </Item>

      {items.map((edge, index) => {
        const active = currentIndex === index

        if (edge.link.url === '/') {
          return (
            <Item key={index} active={currentIndex === index}>
              <Link to={edge.link.url}>{edge.link.title}</Link>
            </Item>
          )
        }

        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <React.Fragment key={index}>
            {edge.link.url !== '#' ? (
              <Item
                key={index}
                hasSubmenu={edge.submenu && edge.submenu.length > 0}
                active={currentIndex === index}
              >
                <CustomLink to={edge.link.url}>{edge.link.title}</CustomLink>
                {edge.submenu && active && (
                  <div className="pt-5">
                    <Submenu>
                      {edge.submenu.map((submenu) => (
                        <li>
                          <Link to={submenu.link.url}>
                            <span>{submenu.link.title}</span>
                          </Link>
                        </li>
                      ))}
                    </Submenu>
                  </div>
                )}
              </Item>
            ) : (
              <Item
                onMouseEnter={() => isDesktop && setCurrentIndex(index)}
                onMouseLeave={() => isDesktop && setCurrentIndex(-1)}
                active={currentIndex === index}
                hasSubmenu={edge.submenu && edge.submenu.length > 0}
              >
                <div
                  role="button"
                  className="link"
                  onClick={() => {
                    if (!isDesktop) {
                      if (currentIndex === index) {
                        return setCurrentIndex(-1)
                      }
                      return setCurrentIndex(index)
                    }
                    return setCurrentIndex(index)
                  }}
                >
                  <span>{edge.link.title}</span>

                  {edge.submenu && active && (
                    <Submenu>
                      <ul className="position-relative w-full h-full">
                        {edge.submenu.map((submenu) => (
                          <li>
                            <Link to={submenu.link.url}>
                              <svg
                                width="6"
                                height="11"
                                viewBox="0 0 6 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 11L6 5.5L0 0L0 11Z"
                                  fill="#D50021"
                                />
                              </svg>
                              <span>{submenu.link.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Submenu>
                  )}
                </div>
              </Item>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Header
