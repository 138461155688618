/* eslint-disable react/function-component-definition */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Plaatjie from '@ubo/plaatjie'

import ButtonDefault from '../elements/ButtonDefault'
import { mainTheme } from '../../styles/Theme'
import logoWhite from '../../img/logo-white.svg'
import ParseContent from './ParseContent'
import Linkedin from '../Linkedin'
import Facebook from '../Facebook'
import Instagram from '../Instagram'
import lineSvg from '../../img/line.svg'

const StyledFooter = styled.footer`
  background-color: ${(props) => props.theme.color.main};
  color: ${(props) => props.theme.color.text.secondary};
  overflow: hidden;
`

const FooterTop = styled.div`
  background-color: ${(props) => props.theme.color.contrast};

  @media (max-width: 991.98px) {
    h3 {
      font-size: 20px;
      margin-bottom: 15px !important;
    }
  }
`

const FooterTitle = styled.span`
  font-size: 25px;
  font-weight: bold;
  font-family: ${(props) => props.theme.font.family.secondary};
  color: #fff;
  margin-bottom: 10px;
  display: block;
`

const FooterList = styled.ul`
  color: #fff;
  text-transform: uppercase;
  line-height: 30px;
  list-style: none;
  padding-left: 0;

  @media (min-width: 576px) {
    font-size: ${(props) => props.theme.font.size.sm};
  }

  @media (max-width: 575px) {
    font-size: ${(props) => props.theme.font.size.s};
  }

  p {
    margin-bottom: 0;
  }

  li {
    display: flex;
    margin: -5px 0 -5px;

    a {
      padding: 5px 0;
      color: #fff;
      text-decoration: unset;
      &:hover {
        text-decoration: underline;
      }
    }

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      margin-right: 15px;
      margin-top: 13px;
      border-left: 6px solid ${(props) => props.theme.color.contrast};
    }
  }
`

const Line = styled.div`
  width: 120%;
  img {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
`

const Logo = styled.img`
  max-width: 33px;
`

const Socials = styled.div`
  display: inline-block;

  & a {
    display: inline-block;
  }

  & svg {
    width: 27px;
    height: 27px;
  }
`

const Footer = ({ subFooter }) => {
  const {
    wpComponent: {
      componentFooter: { footertop, menu1, menu2, menu3, copyright },
    },
    background,
  } = useStaticQuery(graphql`
    {
      wpComponent(databaseId: { eq: 47 }) {
        componentFooter {
          footertop {
            title
            titleunder
            link {
              title
              url
            }
          }

          menu1 {
            title
            links {
              ... on WpPage {
                id
                uri
                title
              }
            }
          }

          menu2 {
            title
            links: projectCat {
              pageLink {
                title
                url
              }
            }
          }

          menu3 {
            title
            content
          }

          copyright {
            text
            link
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 80, quality: 100)
                }
              }
            }
            logoVca {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 130, quality: 100)
                }
              }
            }
            logoKomo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 130, quality: 100)
                }
              }
            }
            linkKomo {
              title
              url
            }
            ssb {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 130, quality: 100)
                  }
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }

      background: file(relativePath: { eq: "footer-background.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 2000, quality: 100)
        }
      }
    }
  `)

  return (
    <StyledFooter>
      {subFooter && (
        <FooterTop className="py-4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-1" />
              <div className="col-lg-6">
                <h3 className="mb-0 font-weight-100">{footertop.title}</h3>
              </div>
              <div className="col-lg-5 d-flex justify-content-center">
                <ButtonDefault
                  wedgeColor={mainTheme.color.contrast}
                  black
                  to={footertop.link.url}
                >
                  {footertop.link.title}
                </ButtonDefault>
              </div>
            </div>
          </div>
        </FooterTop>
      )}

      <BackgroundImage fluid={background}>
        <div className="container">
          <div className="row">
            <div className="col-lg-1" />
            <div className="col-lg-11 d-flex py-4 align-items-center">
              <Logo className="mr-3 mr-sm-5" src={logoWhite} alt="" />
              <span className="font-size-xl font-italic color-text-contrast">
                {footertop.titleunder}
              </span>
            </div>
          </div>
        </div>

        <Line className="position-relative">
          <img src={lineSvg} alt="" />
        </Line>

        <div className="container z-index-2 position-relative">
          <div className="row mt-5 justify-content-center px-lg-5">
            <div className="col-lg-4">
              <FooterTitle>{menu1.title}</FooterTitle>
              <FooterList>
                {menu1.links.map((link) => (
                  <li key={link.title}>
                    <Link to={link.uri}>{link.title}</Link>
                  </li>
                ))}
              </FooterList>
            </div>

            <div className="col-lg-4">
              <FooterTitle>{menu2.title}</FooterTitle>
              <FooterList>
                {menu2.links.map(({ pageLink: { title, url } }) => (
                  <li key={title}>
                    <Link to={url}>{title}</Link>
                  </li>
                ))}
              </FooterList>
            </div>

            <div className="col-lg-4">
              <FooterTitle>{menu3.title}</FooterTitle>
              <FooterList>
                <li className="align-items-start">
                  <ParseContent content={menu3.content} />
                </li>
              </FooterList>
              <Socials>
                <Linkedin className="ml-3" white />
                <Facebook className="ml-3" white />
                <Instagram className="ml-3" white />
              </Socials>
            </div>
          </div>

          <div className="row justify-content-end pb-2 mt-4">
            <div className="col-lg-8 d-flex flex-lg-row flex-column mt-lg-0 mt-3 justify-content-end align-items-center">
              <Link
                className="text-white text-decoration-none font-size-m py-1 px-2"
                to="/algemene-voorwaarden"
              >
                Algemene voorwaarden
              </Link>
              <span className="d-lg-inline d-none color-text-contrast">|</span>
              <Link
                className="text-white text-decoration-none font-size-m py-1 px-2 mt-3 mt-lg-0"
                to="/privacyverklaring"
              >
                Privacy statement
              </Link>
              <span className="d-lg-inline d-none color-text-contrast">|</span>
              <p className="d-flex align-items-center mb-0 px-2">
                <span className="mr-2">
                  <ParseContent content={copyright.text} />
                </span>
                <a
                  href="https://www.bouwenmetstaal.nl/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Plaatjie
                    image={copyright.logo}
                    alt="Lid van Bouwen met Staal"
                  />
                </a>
              </p>
              <span className="d-lg-inline d-none color-text-contrast">|</span>
              <p className="mb-0 px-2">
                <Plaatjie image={copyright.logoVca} alt="VCA" />
              </p>
              <p>
                <a
                  href={copyright.linkKomo.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Plaatjie
                    image={copyright.logoKomo}
                    alt="KOMO"
                    style={{ width: 65, marginTop: 13 }}
                  />
                </a>
              </p>
              <p>
                <a href={copyright.ssb.link.url} target="_blank" rel="noreferrer">
                  <Plaatjie
                    image={copyright.ssb.image}
                    alt="Stichting Staalbouwkwaliteit"
                    style={{ width: 95, marginTop: 13 }}
                  />
                </a>
              </p>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </StyledFooter>
  )
}

export default Footer
