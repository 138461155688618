/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'

const removeHTML = (content) => {
  let newContent = content

  if (typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

const SEO = ({ seo, socialImage }) => {
  const {
    site,
    // eslint-disable-next-line
  } = useStaticQuery(graphql`
    query socialSharingQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const defaultImageUrl = socialImage
    ? {
        url: `${site.siteMetadata.siteUrl}${socialImage}`,
      }
    : ''

  const image = defaultImageUrl

  return (
    <>
      <GatsbySeo
        language="nl"
        title={removeHTML(seo.title) || ''}
        description={removeHTML(seo.metaDesc) || ''}
        canonical={seo.canonical || ''}
        openGraph={{
          type: seo.opengraphTitle || '',
          url: seo.opengraphUrl || '',
          title: removeHTML(seo.title) || '',
          description: removeHTML(seo.metaDesc) || '',
          images: [image],
        }}
      />
    </>
  )
}

export default SEO
