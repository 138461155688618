import React from 'react'

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="221"
      height="192"
      viewBox="0 0 221 192"
    >
      <path
        fill="#1c1c1c"
        d="M390 0v151.085L349.152 192H169V0z"
        transform="translate(-169)"
      />
      <path
        fill="#fff"
        d="M9.4 0L0 9.3V70h24.369l9.707-9.3v-4.757h-9.707V60.7H4.955L9.707 56V9.3h14.662V14h9.707V0z"
        transform="translate(-169) translate(259 26) translate(.198)"
      />
      <path
        fill="#d50032"
        d="M26.86 17.006l-2.431 2.443v2.391H36.17v10.136h-4.654l-2.69 2.443v2.443h7.812v11.694H26.601l-2.431 2.391v2.443h12.623l4.811-4.834v-12.11l-2.328-2.287 1.707-1.715V17.006z"
        transform="translate(-169) translate(259 26) translate(.198)"
      />
      <g fill="#fff">
        <path
          d="M2.671 0L0 2.657v8.257h6.2v6.429H1.321L0 18.657V20h6.2l2.76-2.657V9.571L7.61 8.228h-6.2l1.35-1.314V2.657h4.62L8.7 1.343V0zM11.771 0l-1.366 1.343v1.314h3.952V20h1.424l1.366-1.343v-16h2.587L21.1 1.343V0zM23.572 20H21.1v-1.343L25.313 0h2.557l4.214 18.657V20h-2.5l-.758-3.715h-4.468L23.572 20zm3.006-14.343L25.2 12.314l-1.32 1.314h4.383l-1.685-7.971zM36.867 20h-2.472v-1.343L38.609 0h2.556l4.214 18.657V20h-2.5l-.759-3.715h-4.467L36.867 20zm3.006-14.343l-1.378 6.657-1.32 1.314h4.383l-1.685-7.971zM49.037 0l-1.346 1.343V20h7.643l1.317-1.343v-1.314h-7.529L50.439 16V0zM60.565 0l-1.313 1.343V20h1.4l1.286-1.343v-7.743h3.629l1.285-1.343V8.228h-6.2l1.283-1.314V2.657h5.253l1.313-1.314V0zM72.2 20h-1.386V1.343L72.114 0h5.491l2.66 2.657v6.429l-2.217 2.228 2.883 7.343V20h-2.356l-3.161-8.257h-1.939v6.914L72.2 20zm1.275-17.343v5.086L72.2 9.086h5.4V2.657zM85.715 20h-2.472v-1.343L87.457 0h2.556l4.213 18.657V20h-2.5l-.758-3.715h-4.467L85.715 20zm3.006-14.343l-1.378 6.657-1.32 1.314h4.383l-1.685-7.971zM106.698 0l-.71.714-3.091 8.829L99.55 0h-1.39l-1.333 1.343V20h1.418l1.3-1.343V7.114l2.5 7.286h1.674l2.525-7.286V20h1.39l1.333-1.343V0zM113.166 0l-1.309 1.343V20h7.968l1.282-1.343v-1.314h-7.857L114.531 16v-5.086h3.626l1.278-1.343V8.228h-6.185l1.281-1.314V2.657h5.294l1.281-1.314V0zM130.542 20h-7.123V1.343L124.786 0h5.5l2.7 2.657v5.257l-1.397 1.372 1.657 1.629v6.429L130.543 20zm-4.332-9.086V16l-1.337 1.343h5.582v-6.429zm0-8.257v4.257l-1.337 1.314h5.437V2.657zM142.75 20h-6.614V2.657L138.688 0h6.7v17.343L142.75 20zm-3.979-17.343V16l-1.29 1.343h5.269V2.657zM156.442 0l-1.263 1.343v16h-5.242L151.2 16V0h-1.345l-1.29 1.343V20h7.959l1.29-1.343V0zM171.515 0l-1.671 12.171-1.674-9.743h-1.925l-1.7 9.743L162.87 0h-2.455v1.343L163.149 20h2.287l1.757-9.486L168.979 20h2.287L174 1.343V0z"
          transform="translate(-169) translate(193 122)"
        />
      </g>
    </svg>
  )
}

export default Logo
